body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html {
  height: 100%;
}
  
.backgroundImage {
  background-image: url("./backgroundImages/front.JPG");
  min-height: 800px;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.textBox {
  background-color: rgba(97, 97, 97, 0.769);
  max-height: 600px;
  width: 100%;
  overflow: scroll;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}

/* unvisited link */
a:link {
  color: rgb(255, 255, 255);
}

/* visited link */
a:visited {
  color: rgb(181, 181, 181);
}

/* mouse over link */
a:hover {
  color: rgb(106, 106, 106);
}

/* selected link */
a:active {
  color: rgb(199, 199, 255);
}

.darkBorder {
  border: solid;
  border-width: 5px;
}
